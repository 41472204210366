import $ from "jquery";

$(window).on("load", function() {

    const headerHeight = $("header").outerHeight();
    const mainHeight = $("main").outerHeight();
    const footerHeight = $("footer").outerHeight();
    const winH = window.innerHeight;

    const missingHeight = winH-footerHeight-headerHeight-mainHeight;
});