import $ from "jquery";

$(function() {
    return; 
    
    $(".bl-heroillu").each(function() {
        const $bl = $(this);

        const options = {
            root: null, //null=window
            //rootMargin: "0% 0% -5% 0%", //unten -5%
            threshold: 0.3 //triggert, wenn Element zu 30% sichtbar (innerhalb rootMargin)
        };
        const observer = new IntersectionObserver(handleIntersect, options);        
        observer.observe($bl.get(0));
    });

    /*
    document.body.addEventListener("custom:updateBackgroundColor", function(ev) {
        console.log(ev.detail.newColor);
        if(ev.detail.newColor == "white") {
            setTimeout(function() {
                $(".illufg").animate({
                    opacity: 1
                }, 200);
            }, 2000);
        } else {
            $(".illufg").css({
                "opacity": 0
            });
        }    
    });
    */
});

function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
        const percentage = entry.intersectionRatio;
        const $bl = $(entry.target);
        $bl.toggleClass("notinview", percentage<0.3);
    });
};