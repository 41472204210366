import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

$(function() {
    $(".bl-aktuelllist").each(function() {
        const $bl = $(this);

        const numItems = $bl.find(".ctritems").find(".item").length;
        if(numItems <= 3) {
            $bl.find(".allitems").hide();
            $bl.find(".ctrmoreitemstextlist").hide(); //mobile linklist
        }

        $bl.find(".allitems").on("click", function() {
            const $textList = $bl.find(".ctrmoreitemstextlist");
            const isTextListVisible = $textList.is(":visible");
            if(isTextListVisible) {
                //Mobile mode (text link list is visible only on mobile)
                $(window).scrollTo($textList, 500, {
                    offset: -20
                });
                return;
            }

            //Desktop mode
            const $allItemsButton = $(this);

            const isAllItemsVisible = $bl.hasClass("allitemsvisible");
            if(!isAllItemsVisible) {
                //Show items
                $bl.addClass("allitemsvisible");

                $allItemsButton.text("Weniger Beiträge");

                $(window).scrollTo($bl, 500, {
                    offset: 0
                });
                
                //stagger fadeIn hidden items
                let itemIndex=0;
                $bl.find(".item:hidden").each(function() {
                    const $item = $(this);
                    $item.delay(itemIndex*100).fadeIn(300);
                    itemIndex++;
                });
            } else {
                //Hide items
                $bl.removeClass("allitemsvisible");
                $allItemsButton.text("Alle Beiträge");
                $bl.find(".item:nth-child(1n+4)").fadeOut(200);
            }
        });
    });
});

