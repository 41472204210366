import $ from "jquery"

$(function() {
    /*
    const $menubutton = $("#menubutton");
    const $desktopmenu = $("#desktopmenu");
    const $body = $("body");
    $menubutton.on("click", function() {
        const newvisible = !$desktopmenu.hasClass("visible");
        $desktopmenu.toggleClass("visible", newvisible);
        $body.toggleClass("desktopmenuvisible", newvisible);
    });
    */

    const $mobilemenu = $("#mobilemenu");
    const $menubutton = $("#menubuttonmobile");
    const $body = $("body");

    $menubutton.on("click", function() {
        toggleMobileMenu();
    });

    function toggleMobileMenu() {
        const mobileMenuVisible = $mobilemenu.hasClass("visible");
        if(mobileMenuVisible) {
            hideMobileMenu();
        } else {
            showMobileMenu();
        }
    }

    function showMobileMenu() {
        $mobilemenu.addClass("visible");
        $menubutton.addClass("active");
        $body.addClass("mobilemenuvisible");
    }

    function hideMobileMenu() {
        $mobilemenu.removeClass("visible");
        $menubutton.removeClass("active");
        $body.removeClass("mobilemenuvisible");
    }

    function enhanceHtml() {
        $mobilemenu.find("li.hassubmenu > a").append('<svg class="icon icon-menuarrowright"><use xlink:href="#icon-menuarrowright"></use></svg>');
        $mobilemenu.find(".backlink").prepend('<svg class="icon icon-menuarrowleft"><use xlink:href="#icon-menuarrowleft"></use></svg>');
    }
    enhanceHtml();

    document.body.addEventListener("custom:scrolltotarget", function(ev) {
        hideMobileMenu();
    });
});
