import { ready } from "./utils.js";

const OPAQUE = "opaque";

ready(() => {
  const header = document.getElementsByTagName("header")[0];

  if (header) {
    document.addEventListener("scroll", function () {
      const scrollY = window.scrollY;
      if (scrollY > 150) {
        header.classList.add(OPAQUE);
      } else if (scrollY < 10) {
        header.classList.remove(OPAQUE);
      }
    });
  }

  const illuhide = document.getElementById("illuhide");

  if (illuhide) {
    document.addEventListener("scroll", function () {
      const scrollY = window.scrollY;
      if (scrollY > 1100) {
        illuhide.classList.add(OPAQUE);
      } else if (scrollY < 1100) {
        illuhide.classList.remove(OPAQUE);
      }
    });
  }
});

