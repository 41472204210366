import $ from "jquery";

$(function() {
    $(".bl-audioplayer").each(function() {
        const $bl = $(this);

        setupPlayer($bl);
    });
});

function setupPlayer($bl) {
    const htmlPlayer = $bl.find("audio").get(0);
    const $ctrfiles = $bl.find(".ctrfiles");

    function handleClick($a, play) {
        const url = $a.attr("href");

        //set active status
        $ctrfiles.find("a").removeClass("active");
        $a.addClass("active");

        //play
        htmlPlayer.src = url;
        if(play) {
            htmlPlayer.play();
        }
    }

    $ctrfiles.on("click", "a", function(ev) {
        ev.preventDefault();

        const $a = $(this);
        handleClick($a, true);
    });

    //activate first
    const $firstLink = $ctrfiles.find("a").first();
    handleClick($firstLink, false);
}