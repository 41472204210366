import $ from "jquery";

/*
function isElementInViewportPartial(el) {
	var rect = el.getBoundingClientRect();
	var innerh = window.innerHeight || document.documentElement.clientHeight;
    const enteredViewport = rect.top <= innerh*0.4; //Higher number: Show earlier
    const leftViewport = rect.bottom < innerh*0.4; //Lower number: Show longer
	return enteredViewport && !leftViewport;
}
*/

$(function() {
    const $html = $("html");
    const $body = $("body");

    function getSectionAt(xpos, ypos) {
        const elements = document.elementsFromPoint(xpos, ypos);
        for(const element of elements) {
            if(element.tagName === "SECTION") {
                return element;
            }
        }
        return null;
    }

    let currentBgColor = "";
    function checkUpdateBackgroundColor() {
        const currentSection = getSectionAt(window.innerWidth*0.5, window.innerHeight*0.5);
        if(currentSection) {
            const newBgColor = $(currentSection).data("bgcolor");
            if(newBgColor != currentBgColor) {
                updateBackgroundColor(currentBgColor, newBgColor);
                /*
                document.body.dispatchEvent(new CustomEvent("custom:updateBackgroundColor", {
                    detail: {
                        oldColor: currentBgColor,
                        newColor: newBgColor
                    }
                }));
                */
                currentBgColor = newBgColor;
            }
        }
    }

    function updateBackgroundColor(currentBgColor, newBgColor) {
        if(newBgColor == "bluegreen") {
            $html.css({
                "--bodybgoverlay": "#458D8D",
                "--currentbgcolor": "var(--bodybgoverlay)",
                "--textcolor": "#FFF3DD"
            });
        } else if(newBgColor == "yellowgreen") {
            $html.css({
                "--bodybgoverlay": "#b9d4bc",
                "--currentbgcolor": "var(--bodybgoverlay)",
                "--textcolor": ""
            });
        } else if(newBgColor == "yellow") {
            $html.css({
                "--bodybgoverlay": "#fff3dd",
                "--currentbgcolor": "var(--bodybgoverlay)",
                "--textcolor": ""
            });
        } else if(newBgColor == "purple") {
            $html.css({
                "--bodybgoverlay": "#5e5162",
                "--currentbgcolor": "var(--bodybgoverlay)",
                "--textcolor": "#FFF3DD"
            });
        } else {
            $html.css({
                "--bodybgoverlay": "transparent",
                "--currentbgcolor": "var(--defaultbodybg)",
                "--textcolor": ""
            });
        }
    }

    /*
    function updateLogoVisibility() {
        const currentSection = getSectionAt(window.innerWidth*0.5, window.innerHeight*0.1);

        const showlogo = (currentSection ? $(currentSection).data("showlogo")=="1" : false);
        if(showlogo) {
            $body.addClass("logovisible");
        } else {
            $body.removeClass("logovisible");
        }
    }
    */
    
    window.setInterval(checkUpdateBackgroundColor, 150);
    //window.setInterval(updateLogoVisibility, 150);
});

